
  .quill {
    font-family: Roboto;
    font-size: 16px;
    padding-bottom: 23px;
    border-radius: 5px;
    .ql-toolbar{
        border: none;
        border-bottom: 1px solid #f1f1f1;
    }
    .ql-container{
        border: none !important;
        .ql.snow{
            border: none !important;
        }
        .ql-editor{
            max-width: 100%;
            min-height: 102px;
            font-size: 16px !important;
            color: #344054 !important;

            ul{
                padding-left: 15px;
            }
            ol{
                padding-left: 15px;
            }
        }
        .ql-blank {
            font-style: normal;
        }
    }
  }