@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.card-shadow {
		box-shadow: 0px 8px 24px 0px #10182814;
	}
}

body {
	margin: 0;
	font-family:
		"Roboto",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

html,
body {
	height: 100%;
	font-size: 16px !important;
}

body::-webkit-scrollbar {
	display: none;
	min-height: 100vh;
	height: 100%;
}

.rfx-response-table ol {
	list-style: decimal;
	padding: 16px 32px;
}

.rfx-response-table ul {
	list-style: disc;
	padding: 16px 32px;
}

@layer utilities {
	.scrollbar::-webkit-scrollbar {
		height: 4px;
		width: 4px;
	}
}

.userback-button {
	display: none;
}

.quill .ql-toolbar {
	background-color: #eaecf0;
	color: #98a2b3;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
	font-style: normal;
}

.custom-quill .ql-toolbar {
	background-color: #f9fafb;
	border-color: #eaecf0;
	color: #98a2b3;
}

.custom-quill .ql-toolbar .ql-stroke {
	stroke: #98a2b3;
}

.custom-quill .ql-toolbar .ql-fill {
	fill: #98a2b3;
}

.custom-quill .ql-toolbar .ql-stroke:hover {
	stroke: #98a2b3 !important;
}

.custom-quill .ql-toolbar .ql-stroke:focus {
	stroke: #98a2b3 !important;
}

.custom-quill .ql-toolbar .ql-fill:hover {
	fill: #98a2b3 !important;
}

.custom-quill .ql-toolbar .ql-fill:focus {
	fill: #98a2b3 !important;
}

.ql-editor > * {
	color: #101828;
	font-family:
		Roboto,
		Ubuntu,
		Cantarell,
		Fira Sans,
		ui-monospace;
}

.icon-download {
	padding: 10px;
	border: 1px solid #eaecf0;
	border-radius: 8px;
	width: fit-content;
	max-width: 40px;
	max-height: 40px;
}

.ql-editor {
	overflow-y: scroll;
	resize: none;
	height: 80%;
}

.ql-editor::-webkit-resizer {
	height: 20px;
	width: 20px;
	background-color: #d92d20;
	position: absolute;
}

/* SCROLLBAR */

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(208, 213, 221, 1);
	border-radius: 66px;
	-webkit-border-radius: 66px;
	-moz-border-radius: 66px;
	-ms-border-radius: 66px;
	-o-border-radius: 66px;
}

/* SCROLLBAR */
/* Let's get this party started */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.moved {
	box-shadow: none !important;
	top: 45px !important;
	border-radius: 8px !important;
	filter: none !important;
	transition: all 0.4s ease-in-out;
}

.notch {
	height: 44px;
	top: 50px;
	position: fixed;
	cursor: pointer;
	transition: all 0.4s ease-in-out;
	z-index: 101;
	filter: drop-shadow(4px 1px 8px rgba(16, 24, 40, 0.1));
}

.notch-left {
	width: 16px;
	height: 44px;
	background: rgba(249, 250, 251, 1);
	left: 365px;
	top: 50px;
	position: absolute;
	z-index: 9999;
	border-radius: 0px;
	cursor: pointer;
}

.sidemuchildanimate {
	animation: fadeIn 1.5s ease;
}

.profile-hide {
	display: none;
}

.fadeInProfileAnimation {
	animation: fadeInProfile 1.5s ease;
}

.fadeoutAnimation {
	display: none;
}

.small-logo {
	opacity: 0;
}

.profile-logo {
	min-width: 40px;
	height: 40px;
	border-radius: 100%;
	margin: 0 auto;
}

.menuleft-wide {
	margin-left: 37px;
}

.fadeintext {
	transition: opacity 1.5s ease-in-out;
}

.fadeintext.active {
	animation: move 1s ease-in-out;
}

.fadeintext.activelogo {
	animation: move 0.5s ease-in-out;
}

@keyframes move {
	0% {
		opacity: 0;
		/* Start and end at opacity 0 */
	}

	100% {
		opacity: 1;
		/* Middle point at opacity 1 */
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		/* Start and end at opacity 0 */
	}

	100% {
		opacity: 1;
		/* Middle point at opacity 1 */
	}
}

@keyframes fadeInProfile {
	0% {
		display: block;
		opacity: 0;
		/* Start and end at opacity 0 */
	}

	100% {
		display: block;
		opacity: 1;
		/* Middle point at opacity 1 */
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
		/* Start and end at opacity 0 */
	}

	100% {
		opacity: 0;
		/* Middle point at opacity 1 */
	}
}

/* disable time icon */
input[type="time"]::-webkit-calendar-picker-indicator {
	background: none;
	display: none;
}

.dropdown-shadow {
	box-shadow: 0px 8px 24px 0px #10182814;
}

.card-shadow {
	box-shadow: 0px 8px 24px 0px #10182814;
}

/* disable blue border ql editor */
.ql-editor:focus {
	outline: none;
}

/* Remove default success toast style */
.Toastify__toast--success {
	background-color: transparent !important;
	/* Remove background color */
	color: inherit;
	/* Inherit color from parent */
	box-shadow: none;
	/* Remove shadow if needed */
}

/* Ensure other styles are not affected */
.Toastify__toast {
	border-radius: 16px;
	padding: 0px;
	background-color: none !important;
	box-shadow: 0px 8px 24px 0px rgba(16, 24, 40, 0.08);
	border: solid 1px #d0d5dd;
	background-color: #fff;
}

.Toastify__toast-body {
	padding: 0px;
	background-color: none !important;
}

.Toastify__toast-icon {
	display: none;
}

.Toastify__close-button {
	display: none;
}

.after-box-shadow {
	@apply relative;
}

.after-box-shadow:after {
	@apply absolute bottom-0 right-[-10px] top-0 w-[10px] bg-gradient-to-r opacity-50;
	content: "";
	background-image: linear-gradient(
		to right,
		rgba(16, 24, 40, 0.06) 10%,
		transparent 95%
	);
}

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

.svg-danger svg path {
	stroke: #d92d20;
}
